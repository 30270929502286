<template>
	<main>
		<HeaderTab :title="$t('global.titre_nommage_config')"  :return_action="'navigationBack'"/>

		<div id="content">
			<div class="container-fluid">
				<div class="row">
					<div class="col-12">
						<div class="box">
                            <div class="row">
                                <label class="col-12 col-form-label">{{ $t('global.titre_rs_config') }}</label>
                                <div class="col">
                                    <e-select
                                        v-model="selected_rs_config"
                                        id="rsconfig_id"
                                        track-by="rsconfig_id"
                                        label="rsconfig_translated_format"
                                        :placeholder="$t('configs.selected_rs')"
                                        :selectedLabel="$t('configs.selected_rs')"
                                        :options="configs_rs"
                                        :searchable="true"
                                        :allow-empty="false"
                                        :loading="isLoadingRsConfig"
                                        :show-labels="false"
                                    >
                                        <template slot="noOptions">{{ $t('global.list_empty') }}</template>
                                    </e-select>
                                </div>
                                <b-button v-if="allow_save_rs" class="col-2 btn-block" @click.prevent="confirm_change_rs" variant="primary">{{ $t("global.sauvegarder") }}</b-button>
                            </div>
                            <hr>
                            <div class="row">
                                <label class="col-12 col-form-label">{{ $t('global.titre_poulain_config') }}</label>
                                <div class="col">
                                    <e-select
                                        v-model="selected_poulain_config"
                                        id="pconfig_id"
                                        track-by="pconfig_id"
                                        label="pconfig_translated_format"
                                        :placeholder="$t('configs.selected_poulain')"
                                        :selectedLabel="$t('configs.selected_poulain')"
                                        :options="configs_poulain"
                                        :searchable="true"
                                        :allow-empty="false"
                                        :loading="isLoadingPoulainConfig"
                                        :show-labels="false"
                                    >
                                        <template slot="noOptions">{{ $t('global.list_empty') }}</template>
                                    </e-select>
                                </div>
                                <b-button v-if="allow_save_format_poulain" class="col-2 btn-block" @click.prevent="confirm_change_poulain" variant="primary">{{ $t("global.sauvegarder") }}</b-button>
                            </div>
						</div>
					</div>
				</div>
			</div>
		</div>

        <ModalConfirm
            ref="confirm_change_format_rs"
            id="confirm_format"
            :icon="['far', 'save']"
            :icon_accept="['fal', 'level-down']"
            :text_title="$t('modal.change_rs.title')"
            :text_question="$t('modal.change_rs.message')"
            :text_button_ok="$t('modal.general.ok')"
            :text_button_accept="$t('modal.general.ok')"
            :text_button_cancel="$t('modal.general.annuler')"
            :callback_validation="confirmSwitchFormatRs"
            :callback_cancel="cancelSwitchFormatRs"
            :processing="processing"
        />

        <ModalConfirm
            ref="confirm_change_format_poulain"
            id="confirm_format"
            :icon="['far', 'save']"
            :icon_accept="['fal', 'level-down']"
            :text_title="$t('modal.change_poulain.title')"
            :text_question="$t('modal.change_poulain.message')"
            :text_button_ok="$t('modal.general.ok')"
            :text_button_accept="$t('modal.general.ok')"
            :text_button_cancel="$t('modal.general.annuler')"
            :callback_validation="confirmSwitchFormatPoulain"
            :callback_cancel="cancelSwitchFormatPoulain"
            :processing="processing"
        />
	</main>
</template>

<script type="text/javascript">
import Config from "@/mixins/Config.js"
import Horse from "@/mixins/Horse.js"
import Tiers from "@/mixins/Tiers.js"

export default {
	name: "RaisonSocialeConfig",
	mixins: [Config, Horse, Tiers],
	data () {
		return {
            selected_rs_config: null,
            current_rs_config: null,
            configs_rs: [],
            rs_used: null,
            isLoadingRsConfig: false,
            allow_save_rs: false,

            selected_poulain_config: null,
            current_poulain_config: null,
            configs_poulain: [],
            poulain_format_used: null,
            isLoadingPoulainConfig: false,
            allow_save_format_poulain: false,

            processing: false
		}
	},
	created() {
		this.init_component()
	},
	methods: {
		async init_component() {
            this.isLoadingRsConfig = true
			const configs_rs = this.getConfig('rs_config')
            this.rs_used = await this.getCurrentFormatRs()
            this.configs_rs = this.format_configs_rs(configs_rs)
            this.isLoadingRsConfig = false

            this.isLoadingPoulainConfig = true
			const configs_poulain = this.getConfig('p_config')
            this.poulain_format_used = await this.getCurrentFormatPoulain()
            this.configs_poulain = this.format_configs_poulain(configs_poulain)
            this.isLoadingPoulainConfig = false
		},
        // CONFIG RAISON SOCIALE
        format_configs_rs(configs) {
            let c = []
            if(!configs) {
                return []
            }

            for(let i = 0; i < configs.length; i++) {
                let temp = configs[i]

                temp.rsconfig_translated_format = this.getTrad(configs[i].rsconfig_format)
                if(this.rs_used && temp.rsconfig_id == this.rs_used.rsused_format) {
                    this.selected_rs_config = configs[i]
                    this.current_rs_config = configs[i]
                }
                else {
                    if(!this.rs_used && configs[i].rsconfig_format == 'rsconfig.format_default') {
                        this.selected_rs_config = configs[i]
                        this.current_rs_config = configs[i]
                    }
                }

                c.push(temp)
            }

            return c
        },
        async confirm_change_rs() {
            this.$refs.confirm_change_format_rs.openModal()
        },
        async confirmSwitchFormatRs() {
            this.processing = true

            const lang = this.getConfig('lang')
            await this.switchFormatRs(this.selected_rs_config, lang)
            .then(async () => {
                await this.$sync.force(true)
                this.processing = false
                await this.loadConfig()
                await this.init_component()
            })
        },
        cancelSwitchFormatRs() {
            this.$refs.confirm_change_format_rs.closeModal()
        },
        // CONFIG NOMMAGE POULAINS
        format_configs_poulain(configs) {
            let c = []
            if(!configs) {
                return []
            }

            for(let i = 0; i < configs.length; i++) {
                let temp = configs[i]

                temp.pconfig_translated_format = this.getTrad(configs[i].pconfig_format)
                if(this.poulain_format_used && temp.pconfig_id == this.poulain_format_used.pused_format) {
                    this.selected_poulain_config = configs[i]
                    this.current_poulain_config = configs[i]
                }
                else {
                    if(!this.poulain_format_used && configs[i].pconfig_format == 'pconfig.format_fynm') {
                        this.selected_poulain_config = configs[i]
                        this.current_poulain_config = configs[i]
                    }
                }

                c.push(temp)
            }

            return c
        },
        async confirm_change_poulain() {
            this.$refs.confirm_change_format_poulain.openModal()
        },
        async confirmSwitchFormatPoulain() {
            this.processing = true

            this.$refs.confirm_change_format_poulain.closeModal()

            await this.switchFormatPoulain(this.selected_poulain_config)
            .then(async () => {
                await this.$sync.force(true)
                this.processing = false
                this.$refs.confirm_change_format_poulain.closeModal()
                await this.loadConfig()
                await this.init_component()
            })
        },
        cancelSwitchFormatPoulain() {
            this.$refs.confirm_change_format_poulain.closeModal()
        }
	},
    watch: {
        selected_rs_config(val) {
            if(val != this.current_rs_config) {
                this.allow_save_rs = true
            }
            else {
                this.allow_save_rs = false
            }
        },
        selected_poulain_config(val) {
            if(val != this.current_poulain_config) {
                this.allow_save_format_poulain = true
            }
            else {
                this.allow_save_format_poulain = false
            }
        }
    },
	components: {
		CustomTable : () => import('GroomyRoot/components/Table/CustomTable'),
        HeaderTab: () => import('@/components/HeaderTab'),
        ModalConfirm: () => import('GroomyRoot/components/Modals/ModalConfirm')
	}
}
</script>
